<template>
  <v-card>
    <v-card-title>
      User Activities
      <v-spacer />
      <v-text-field
        class="pr-4"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on"
            href="https://redirection.insightzclub.com/status/reward"
            target="_blank"
            color="primary"
          >Check User Activity Sync Status</v-btn>
        </template>

        <span>
          Username: melvin.c@insightzclub.com<br>Password: test123*
        </span>
      </v-tooltip>
    </v-card-title>

    <div class="px-4">
      <v-alert :value="exportDisable" 
        colored-border
        dismissible
        color="primary lighten-1"
        border="top"
        icon="mdi-check"
        transition="scale-transition"
        elevation="2"
        prominent
      >
        🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is completed 🏁, you will receive a notification via Slack under <code>#admindashboard</code> channel 📢.
      </v-alert>

      <v-row>
        <v-col>
          <v-autocomplete
            :items="projects"
            :item-text="item => $languageProcessor.getEnText(item.name)"
            item-value="_id"
            autocomplete
            persistent-hint
            hint="Choose the project and click select"
            v-model="dataToSend.projectId"
            label="Choose Project"
            v-on:change="setProjectAndGetActivity"
          />
        </v-col>

        <v-col>
          <v-select v-if="dataToSend.projectId"
            :items="activities"
            :item-text="item => $languageProcessor.getEnText(item.name)"
            item-value="_id"
            multiple
            v-model="dataToSend.activityIds"
            label="Filter By Activity"
          />
        </v-col>
      </v-row>

      <!-- Switch -->
      <v-row>
        <v-col>
          <v-switch
            v-model="filterStartDate"
            :label="`Filter by Start Date`"
            color="primary"
          ></v-switch>
        </v-col>

        <v-col>
          <v-switch
            v-model="filterEndDate"
            :label="`Filter by End Date`"
            color="primary"
          ></v-switch>
        </v-col>

        <v-col>
          <v-menu v-if="filterStartDate"
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.startDate"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              no-title
              scrollable
              v-model="dataToSend.startDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.startDateMenu.save(Date(startDate))"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-menu v-if="filterEndDate"
            ref="endDateMenu"
            v-model="endDateMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.endDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              no-title
              color="primary"
              scrollable
              v-model="dataToSend.endDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateMenu.save(Date(endDate))"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            :items="[
              { key: 'I', label: 'Invalid' },
              { key: 'A', label: 'Active' },
              { key: 'T', label: 'Terminate' },
              { key: 'C', label: 'Complete' }
            ]"
            label="Activity Status"
            multiple
            deletable-chips
            v-model="dataToSend.activityStatuses"
            item-text="label"
            item-value="key"
            persistent-hint
            hint="Choose Activity Statuses"
          ></v-select>
        </v-col>

        <v-col>
          <v-select
            :items="[
              { key: 'True', value: true },
              { key: 'False', value: false },
            ]"
            label="Valid Status"
            deletable-chips
            v-model="dataToSend.validStatus"
            item-text="key"
            item-value="value"
            persistent-hint
            hint="Filter by Activity Validity"
            multiple
          />
        </v-col>
      </v-row>

      <!-- Check box for technographic and appographic -->
      <v-row class="px-3">
        <v-checkbox v-model="dataToSend.includeDateFilterDataOnly" label="Show & Export Date Filter Data" color="primary" class="pr-4" :disabled="!filterStartDate && !filterEndDate" />
        <v-checkbox v-model="dataToSend.excludeActivityData" label="Exclude Submission Data" color="primary" class="pr-4" />
        <v-checkbox v-model="dataToSend.technographics" label="Include Technographic In Export" color="primary" class="pr-4" :disabled="dataToSend.exportType == 'submission'" />
        <v-checkbox v-model="dataToSend.appographics"  label="Include Appographic In Export" color="primary" class="pr-4" :disabled="dataToSend.exportType == 'submission'" />
      </v-row>

      <!-- Export output type -->
      <v-radio-group v-model="dataToSend.exportType" row>
        <v-radio
          label="Export by User (Each row is a user)"
          value="user"
        ></v-radio>
        <v-radio
          :disabled="dataToSend.activityIds.length > 1"
          label="Export by Submission (Each row is a submission)"
          value="submission"
        ></v-radio>
      </v-radio-group>
      
      <v-row>
        <v-col>
          <v-btn :disabled="dataToSend.projectId == null"
            class="primary"
            @click="fetchUserActivity"
          >Load</v-btn>

          <v-btn :disabled="dataToSend.projectId == null"
            class="primary ml-4"
            @click="() => exportUserActivityToFile()"
          >Export</v-btn>
        </v-col>

        <v-col>
          <v-btn :disabled="dataToSend.projectId == null"
            class="primary ml-4"
            @click="() => exportAppUsageToFile()"
          >Export App Usage</v-btn>

          <v-btn :disabled="dataToSend.projectId != '5d39547d36dc1e55888b6d52'"
            href="/mobile/userActivity/qualityCheck"
            target="_blank"
            class="primary ml-4"
          >Quality Check</v-btn>
        </v-col>
      </v-row>
    </div>
    
    <!-- Data Table -->
    <v-data-table v-if="dataTableReady"
      :headers="headers"
      :items="userActivity"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/userActivity/${item.userId}/${item.projectId}/edit` }) ">edit</v-icon>  -->
        <a :href="generateEditUrl(`/mobile/userActivity/${item.userId}/${item.projectId}/edit`)" target="_blank">View</a>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      exportDisable: false,
      search: "",
      projects: [],
      activities: [],
      userActivity: [],
      headers: [
        { text: "UserId", value: "userId", sortable: true },
        { text: "ProjectId", value: "projectId", sortable: true },
        { text: "Activities", value: "submissions", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Action", value: "actions", sortable: false },
      ],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      filterStartDate: false,
      filterEndDate: false,
      dataToSend: {
        projectId: null,
        startDate: undefined,
        endDate: undefined,
        activityStatuses: [ "I", "A", "T", "C" ],
        activityIds: [],
        technographics: false,
        appographics: false,
        includeDateFilterDataOnly: true,
        dateFilterDataURLParams: null,
        validStatus: [ true, false ],
        exportType: "user",
      },
      dataTableReady: true,
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    setProjectAndGetActivity(projectId) {
      this.$setLoader();

      service.fetchActivities({ projectId }).then(r => {
        this.activities = r.data;
        this.dataToSend.activityStatuses = [ "I", "A", "T", "C" ]
        this.dataToSend.validStatus = [ true, false ]
        this.dataToSend.activityIds = r.data.map(a => a._id)
        this.dataToSend.exportType = "user"
        this.$disableLoader();
      });
    },
    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    },
  
    fetchUserActivity() {
      this.$setLoader();

      service.fetchUserActivities(this.dataToSend).then(r => {
        this.userActivity = r.data;
        this.$disableLoader();
        this.exportDisable = false
      });
    },
    exportUserActivityToFile() {
      service.exportUserActivity(this.dataToSend)
      this.exportDisable = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    exportAppUsageToFile() {
      service.exportAppUsages(this.dataToSend)
      this.exportDisable = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    generateEditUrl(url) {
      return (this.dataToSend.dateFilterDataURLParams == null) ? url : `${url}?${this.dataToSend.dateFilterDataURLParams}`
    },

    generateDateFilterURLParams() {
      if (this.dataToSend.includeDateFilterDataOnly) {
        if (this.filterStartDate && this.filterEndDate && this.dataToSend.startDate && this.dataToSend.endDate) {
          return `startDate=${this.formatDate(this.dataToSend.startDate)}&endDate=${this.formatDate(this.dataToSend.endDate)}`
        } else if (this.filterStartDate && !this.filterEndDate && this.dataToSend.startDate) {
          return `startDate=${this.formatDate(this.dataToSend.startDate)}`
        } else if (!this.filterStartDate && this.filterEndDate && this.dataToSend.endDate) {
          return `endDate=${this.formatDate(this.dataToSend.endDate)}`
        }
      }

      return null
    },

    formatDate(date) {
      return new Date(date).toISOString().substring(0, 10)
    },

    dateDataWatcher() {
      this.dataTableReady = false
      this.dataToSend.dateFilterDataURLParams = this.generateDateFilterURLParams()

      this.$nextTick (() => { 
        this.dataTableReady = true;
      });
    }
  },

  watch: {
    "dataToSend.startDate": function() {
      this.dateDataWatcher()
    },

    "dataToSend.endDate": function() {
      this.dateDataWatcher()
    },

    "filterStartDate": function() {
      this.dateDataWatcher()
    },

    "filterEndDate": function() {
      this.dateDataWatcher()
    },

    "dataToSend.exportType": function(val) {
      if (val == "submission") {
        this.dataToSend.technographics = false
        this.dataToSend.appographics = false
      }
    },

    "dataToSend.activityIds": function(val) {
      if (val.length > 1) {
        this.dataToSend.exportType = "user"
      }
    }
  }
};
</script>